import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation, Navigate } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import { Box, Grid, Typography, Paper, Button, Snackbar, Alert, Slide } from '@mui/material'
//import { styled } from '@mui/system'
import UploadIcon from '@mui/icons-material/Upload'
import { db, storage } from '../initfirebase'
import { getAuth, signInAnonymously } from 'firebase/auth'
import { collection, addDoc, serverTimestamp } from "firebase/firestore"
import { ref, uploadString } from "firebase/storage"

const useStyles = makeStyles({
    paperstyle: {
        margin: '8px 0',
        padding: '16px 8px',
        width: '100%',
        '@media (min-width:600px)': {
            maxWidth: "600px",
            padding: '32px 32px',
            margin: '0.75rem 0',
        },
    },
    uploadedimg: {
        width: "80%",
        height: "auto",
        '@media (min-width:600px)': {
            maxWidth: "400px",
            height: "auto",
        },
    },
    uploadbtn: {
        color: '#E59717',
        borderRadius: '15px',
        backgroundColor: '#ffffff',
        boxShadow: 'none',
        border: '2px solid #E59717',
        width: '200px',
        '&:hover':{
            border: '3px solid #E59717',
            backgroundColor: '#ffffff',
            boxShadow: 'none',
        },
    },
});

export default function Payment({orderqty, setOrderqty, userinfo, setUserinfo}) {
    let navigate = useNavigate()
    let location = useLocation()

    const classes = useStyles()

    const [open, setOpen] = useState(false)
    useEffect(()=>{ console.log (open) },[open])

    const [uploadimgurl, setUploadimgurl] = useState({
        url: "",
        file: "",
    }) //Hook for storing receipt image
    useEffect(()=>{ console.log (uploadimgurl) },[uploadimgurl])

    const [submitted, setSubmitted] = useState(false)
    useEffect(()=>{
        let uploaddb =true //control flag
        if ((submitted) && (userinfo.orderid==='')) {

            const auth = getAuth()
            signInAnonymously(auth)
            .then(() => { //Anonymous Login Successfully

                //Write to the database
                async function adddata() {
                    try {
                        const docRef = await addDoc(collection(db, 'orders'), {
                            datetime: serverTimestamp(),
                            name: userinfo.name,
                            phone: userinfo.phone,
                            email: userinfo.email,
                            address: userinfo.address,
                            orderitems: { df: orderqty.df, s: orderqty.s, sn: orderqty.sn, hc: orderqty.hc, pz: orderqty.pz, hz: orderqty.hz, cf: orderqty.cf, cm: orderqty.cm },
                            totalqty: orderqty.total,
                            price: orderqty.price,
                            status: 'checking'
                        });
                        //console.log("Document written with ID: ", docRef.id);
                        
                        //Uploading files
                        let filepath = `xmas2021receipt/${docRef.id+uploadimgurl.file.name}`
                        const storageRef = ref(storage, filepath) //Where and what file name do you want to save
                        //console.log(storageRef.fullPath)
                        uploadString(storageRef, uploadimgurl.url, 'data_url').then((snapshot) => {
                            //console.log(snapshot)
                            //Successfully uploaded

                            if (uploaddb) {
                                setUserinfo({...userinfo, orderid: docRef.id.substring(0, 6)})
                                setSubmitted(false)
                                setOpen(false)
                                navigate('/finished', { replace: true })
                            }
                        })
                        .catch((error) => {
                            console.log (error)
                        });
                    } catch (e) {
                        console.error("Error adding document: ", e);
                    }
                }
                adddata()
            })
            .catch((error) => {
                console.log (error.code, error.message)
            });
        }
        return () => {uploaddb = false}
    },[submitted, setSubmitted, setUserinfo, userinfo, orderqty, uploadimgurl, navigate])
        

    const handleUpload = (event) => { //Upload button
        //console.log (event.target.files[0])
        if (event.target.files[0].size <= 3000000) {
            const fileReader = new FileReader()
            fileReader.readAsDataURL(event.target.files[0]) //Read the (local machine) url of the file 
            fileReader.onload = () => {
                //console.log (fileReader.result)
                setUploadimgurl({ url: fileReader.result, file: event.target.files[0] }) //Finished upload and put the local url into the hook
            }
        } else {
            alert ('上傳圖片不可超過 3 mb, 請重新上傳')
        }
    }

    const TransitionUp = (props) => {
        return <Slide {...props} direction="up" in={open} timeout={2000} />
    }

    return (
        <React.Fragment>
        {
            !orderqty.package ?
            <Navigate to="/" state={{ from: location }} />
            :
            <Box sx={{ //Background
                    p: 2,
                    display: 'flex',
                    flexWrap: 'wrap',
                    bgcolor: '#F5F1F1', //Background Color
                }}
                justifyContent="center"
            >
                <Paper elevation={0} className={classes.paperstyle}>
                    <Grid item xs={12}>
                        <Typography variant="h2" align="center">
                            付款金額 ${orderqty.price}
                        </Typography>
                    </Grid>
                </Paper>
                <Paper elevation={0} className={classes.paperstyle}>
                    <Typography variant="h2" style={{marginBottom: '16px',}}>
                        付款方式
                    </Typography>
                    <ol style={{marginLeft: '-16px',}}>
                        <li style={{marginBottom: '24px',}}>
                            <Typography variant="body1" style={{marginBottom: '8px',}}>
                                銀行過戶 / 櫃員機轉帳
                            </Typography>
                            <Typography variant="body2">
                                帳戶名稱: Go 2 Health Group Limited <br />
                                銀行: 東亞銀行 <br />
                                帳戶號碼: (015)-525-68-00848-2
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body1" style={{marginBottom: '8px',}}>
                                轉數快 (FPS)
                            </Typography>
                            <Typography variant="body2">
                                FPS ID: 2638690<br />
                                帳戶名稱: Go 2 Health Group Limited<br />
                                銀行: 東亞銀行
                            </Typography>
                        </li>
                    </ol>
                </Paper>
                <Paper elevation={0} className={classes.paperstyle}>
                    <Box sx={{my: 2, display: 'flex',}} justifyContent="center">
                        <label htmlFor="contained-button-file">
                            <input hidden accept="image/*" id="contained-button-file" multiple type="file" onChange={handleUpload} />
                            <Button variant="contained" className={classes.uploadbtn} component="span" startIcon={<UploadIcon fontSize="inherit"/>}>
                                上傳收據
                            </Button>
                        </label>
                    </Box>
                    <Grid item xs={12} align="center" style={{marginBottom: '24px'}}>
                        <Typography variant="body1" align="center" style={{fontSize: '12px' }}>
                            *上傳圖片不可超過 3 mb
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1" align="center">
                            完成付款後，請在此上傳收據
                        </Typography>
                    </Grid>
                    {
                        (uploadimgurl.url) &&
                            <Box>
                                <Box sx={{my: 4, display: 'flex',}} justifyContent="center">
                                    <img src={uploadimgurl.url} alt="" className={classes.uploadedimg} />
                                </Box>
                                {
                                    orderqty.price >0 &&
                                    <Grid item xs={12} align="center" style={{marginTop: '24px',}}>
                                        <Button variant="contained"  onClick={()=>{ setOpen(true); setSubmitted(true) }} disableElevation>
                                            提交及完成訂單                     
                                        </Button>
                                        <Snackbar
                                            open={open}
                                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                            TransitionComponent={TransitionUp}
                                        >
                                            <Alert severity="info" sx={{ width: '100%' }}>
                                                處理訂單中，請稍後 ...
                                            </Alert>
                                        </Snackbar>
                                    </Grid>
                                }
                            </Box>
                    }
                </Paper>
            </Box>
        }
        </React.Fragment>
    )
}
