import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import { ThemeProvider } from '@mui/material/styles'
import theme from './theme'
import LoginProvider from './LoginProvider'

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <LoginProvider>
        <App />
      </LoginProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
