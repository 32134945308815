import React from 'react'
import { useLocation, Navigate } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import { Box, Grid, Typography, Paper } from '@mui/material'


const useStyles = makeStyles({
    paperstyle: {
        margin: '8px 0',
        padding: '24px 8px',
        width: '100%',
        '@media (min-width:600px)': {
            maxWidth: "600px",
            padding: '32px 32px',
            margin: '0.75rem 0',
        },
    },
  });

export default function Finished({ userinfo }) {

    let location = useLocation()
    const classes = useStyles()

    let emailmsg = `mailto:info@go2health.com.hk?subject=Questions about order ${userinfo.orderid}`
    let wamsg = `https://wa.me/+85265582523?text=我想問訂單${userinfo.orderid}相關的問題`

    return (
        <React.Fragment>
        {
            !userinfo.orderid ?
            <Navigate to="/" state={{ from: location }} />
            :
            <Box sx={{ //Background
                    p: 2,
                    display: 'flex',
                    flexWrap: 'wrap',
                }}
                justifyContent="center"
            >
                <Paper elevation={0} className={classes.paperstyle}>
                    <Grid item xs={12}>
                        <Typography paragraph variant="h2" align="center">
                            多謝訂購 QCHEFS 產品 <br />
                        </Typography>
                        <Typography paragraph variant="h2" align="center" style={{fontWeight: '600', margin: '1.5rem 0'}}>
                            訂單編號：{userinfo.orderid} <br />
                        </Typography>
                        <Typography paragraph variant="h2" align="center">
                            我們會再發電郵確認訂單狀況。 <br />
                            如 2 日內還未收到電郵，請跟我們聯絡。 <br />
                        </Typography>
                        <Typography paragraph variant="h2" align="center" style={{fontSize: '1rem'}}>
                            E-Mail: <a href={emailmsg}>info@go2health.com.hk</a><br />
                            WhatsApp: <a href={wamsg} target="_blank" rel="noreferrer">(852) 65582523</a>
                        </Typography>
                    </Grid>
                </Paper>
            </Box>
        }
        </React.Fragment>
    )
}
