import React from 'react'
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles'
import { Box, Grid, Typography, Paper, Divider, Select, InputLabel, MenuItem, FormControl, TextField, Button, Alert } from '@mui/material'
import df from '../img/DentalFlakesDog.png'
import s from '../img/Sizzles.png'
import sn from '../img/snackies.png'
import hc from '../img/hardCracker.png'
import pz from '../img/PuffedCheese.png'
import hz from '../img/HardCheese.png'
import cf from '../img/DentalFlakesCat.png'
import cm from '../img/MminisCat.png'

const useStyles = makeStyles({
    productpic: {
        width: '100px',
        height: 'auto',
        '@media (min-width:600px)': {
            width: "150px",
            height: "auto",
        },
    },
    paperstyle: {
        margin: '8px 0',
        padding: '16px 8px',
        width: '100%',
        '@media (min-width:600px)': {
            maxWidth: "600px",
            padding: '32px 32px',
            margin: '0.75rem 0',
        },
    },
  });

export default function Order({orderqty, setOrderqty, userinfo, setUserinfo}) {
    let navigate = useNavigate()
    const classes = useStyles()

    //Handle order form changes
    const handleQtyChange = (prop) => (event) => {
        let temp = { ...orderqty, [prop]: event.target.value }
        temp.total=temp.df+temp.s+temp.sn+temp.hc+temp.pz+temp.hz+temp.cf+temp.cm
        if (( temp.total%4 === 0 ) && ( temp.total > 0 )) {
            temp.package = true
            let paiditems = temp.total - temp.total/4
            if (temp.hz >= paiditems) { temp.price = paiditems*138 } //All HZ
            else { 
                paiditems=paiditems-temp.hz
                if ((temp.pz+temp.cf+temp.cm) >= paiditems) {temp.price = temp.hz*138 + paiditems*119}
                else {
                    paiditems=paiditems-(temp.pz+temp.cf+temp.cm)
                    temp.price = temp.hz*138 + (temp.pz+temp.cf+temp.cm)*119 + paiditems*109
                }
            }
        } else {
            temp.package = false
            temp.price = 0
        }
        setOrderqty({...temp, package: temp.package, price: temp.price})
    }

    //Handle user form changes
    const handleUserChange = (prop) => (event) => {
        if (prop==='phone') {
            if (!event.target.value) {
                setUserinfo({...userinfo, [prop]: event.target.value})
            } else {
                let inputint = parseInt(event.target.value)
                if ((inputint) && (inputint< 20000000000)) {
                    setUserinfo({...userinfo, [prop]: inputint})
                }
            }
        } else {
            setUserinfo({...userinfo, [prop]: event.target.value})
        }
    }
    
    return (
        <Box sx={{ //Background
                p: 2,
                display: 'flex',
                flexWrap: 'wrap',
                bgcolor: '#F5F1F1', //Background Color
            }}
            justifyContent="center"
        >
            {
                //BASIC CLEANING SERIES
            }
            <Paper elevation={0} className={classes.paperstyle}>
                <Grid item xs={12}>
                    <Typography variant="h2" style={{marginBottom: '16px',}}>
                        基本清潔系列
                    </Typography>
                </Grid>
                <Grid container item>
                    <Grid item xs={4}>
                        <img src={df} alt="Dental Flakes for Dog" className={classes.productpic}/>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="body1" style={{marginBottom: '16px',}}>
                        Dental Flakes
                        <br />
                        蕎麥芝士潔齒粉
                        </Typography>
                        <Typography variant="body1" style={{marginBottom: '16px',}}>
                            $109
                        </Typography>
                        <Typography variant="body2" style={{marginBottom: '16px',}}>
                            屬性：潔齒粉 <br />
                            風味：蕎麥芝士 <br />
                            重量：90g <br />
                            適合犬型：全犬用 <br />
                            建議餵食方法：每天食用
                        </Typography>
                        <Box sx={{ width: 160 }}>
                            <FormControl fullWidth>
                                <InputLabel id="df">訂購數量</InputLabel>
                                <Select
                                    labelId="df_order_lable"
                                    id="df_order"
                                    size="small"
                                    value={orderqty.df}
                                    label="訂購數量"
                                    onChange={handleQtyChange('df')}
                                >
                                    <MenuItem value={0}>0</MenuItem>
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={2}>2</MenuItem>
                                    <MenuItem value={3}>3</MenuItem>
                                    <MenuItem value={4}>4</MenuItem>
                                    <MenuItem value={5}>5</MenuItem>
                                    <MenuItem value={6}>6</MenuItem>
                                    <MenuItem value={7}>7</MenuItem>
                                    <MenuItem value={8}>8</MenuItem>
                                    <MenuItem value={9}>9</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>
                </Grid>
                <Divider style={{ marginTop: '16px', marginBottom: '16px', }}/>
                <Grid container item>
                    <Grid item xs={4}>
                        <img src={s} alt="Sizzles" className={classes.productpic}/>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="body1" style={{marginBottom: '16px',}}>
                        Sizzles
                        <br />
                        蕎麥芝士潔齒小食片
                        </Typography>
                        <Typography variant="body1" style={{marginBottom: '16px',}}>
                            $109
                        </Typography>
                        <Typography variant="body2" style={{marginBottom: '16px',}}>
                            屬性：潔齒小食片 <br />
                            風味：蕎麥芝士 <br />
                            重量：65g <br />
                            適合犬型：全犬用 <br />
                            建議餵食方法：每天食用
                        </Typography>
                        <Box sx={{ width: 160 }}>
                            <FormControl fullWidth>
                                <InputLabel id="s">訂購數量</InputLabel>
                                <Select
                                    labelId="s_order_lable"
                                    id="s_order"
                                    size="small"
                                    value={orderqty.s}
                                    label="訂購數量"
                                    onChange={handleQtyChange('s')}
                                >
                                    <MenuItem value={0}>0</MenuItem>
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={2}>2</MenuItem>
                                    <MenuItem value={3}>3</MenuItem>
                                    <MenuItem value={4}>4</MenuItem>
                                    <MenuItem value={5}>5</MenuItem>
                                    <MenuItem value={6}>6</MenuItem>
                                    <MenuItem value={7}>7</MenuItem>
                                    <MenuItem value={8}>8</MenuItem>
                                    <MenuItem value={9}>9</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>
                </Grid>
                <Divider style={{ marginTop: '16px', marginBottom: '16px', }}/>
                <Grid container item>
                    <Grid item xs={4}>
                        <img src={sn} alt="Snackies" className={classes.productpic}/>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="body1" style={{marginBottom: '16px',}}>
                        Snackies
                        <br />
                        角豆芝士潔齒粒
                        </Typography>
                        <Typography variant="body1" style={{marginBottom: '16px',}}>
                            $109
                        </Typography>
                        <Typography variant="body2" style={{marginBottom: '16px',}}>
                            屬性：脆脆潔齒粒 <br />
                            風味：角豆芝士 <br />
                            重量：65g <br />
                            適合犬型：全犬用 <br />
                            建議餵食方法：每天食用
                        </Typography>
                        <Box sx={{ width: 160 }}>
                            <FormControl fullWidth>
                                <InputLabel id="sn">訂購數量</InputLabel>
                                <Select
                                    labelId="sn_order_lable"
                                    id="sn_order"
                                    size="small"
                                    value={orderqty.sn}
                                    label="訂購數量"
                                    onChange={handleQtyChange('sn')}
                                >
                                    <MenuItem value={0}>0</MenuItem>
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={2}>2</MenuItem>
                                    <MenuItem value={3}>3</MenuItem>
                                    <MenuItem value={4}>4</MenuItem>
                                    <MenuItem value={5}>5</MenuItem>
                                    <MenuItem value={6}>6</MenuItem>
                                    <MenuItem value={7}>7</MenuItem>
                                    <MenuItem value={8}>8</MenuItem>
                                    <MenuItem value={9}>9</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>
                </Grid>
            </Paper>
            {
                //ADVANCED CLEANING SERIES
            }
            <Paper elevation={0} className={classes.paperstyle}>
                <Grid item xs={12}>
                    <Typography variant="h2" style={{marginBottom: '16px',}}>
                        深層清潔系列
                    </Typography>
                </Grid>
                <Grid container item>
                    <Grid item xs={4}>
                        <img src={hc} alt="Hard Cracker" className={classes.productpic}/>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="body1" style={{marginBottom: '16px',}}>
                        Hard Cracker
                        <br />
                        蕎麥芝士(堅硬)潔齒棒
                        </Typography>
                        <Typography variant="body1" style={{marginBottom: '16px',}}>
                            $109
                        </Typography>
                        <Typography variant="body2" style={{marginBottom: '16px',}}>
                            屬性：剛硬潔齒條 <br />
                            風味：蕎麥芝士 <br />
                            重量：72g <br />
                            數量：4 條 <br />
                            適合犬型：大型犬 <br />
                            建議餵食方法：每星期食用
                        </Typography>
                        <Box sx={{ width: 160 }}>
                            <FormControl fullWidth>
                                <InputLabel id="hc">訂購數量</InputLabel>
                                <Select
                                    labelId="hc_order_lable"
                                    id="hc_order"
                                    size="small"
                                    value={orderqty.hc}
                                    label="訂購數量"
                                    onChange={handleQtyChange('hc')}
                                >
                                    <MenuItem value={0}>0</MenuItem>
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={2}>2</MenuItem>
                                    <MenuItem value={3}>3</MenuItem>
                                    <MenuItem value={4}>4</MenuItem>
                                    <MenuItem value={5}>5</MenuItem>
                                    <MenuItem value={6}>6</MenuItem>
                                    <MenuItem value={7}>7</MenuItem>
                                    <MenuItem value={8}>8</MenuItem>
                                    <MenuItem value={9}>9</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>
                </Grid>
                <Divider style={{ marginTop: '16px', marginBottom: '16px', }}/>
                <Grid container item>
                    <Grid item xs={4}>
                        <img src={pz} alt="Puffed Cheese" className={classes.productpic}/>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="body1" style={{marginBottom: '16px',}}>
                        Puffed Cheese
                        <br />
                        重量芝士(鬆化)潔齒條
                        </Typography>
                        <Typography variant="body1" style={{marginBottom: '16px',}}>
                            $119
                        </Typography>
                        <Typography variant="body2" style={{marginBottom: '16px',}}>
                            屬性：鬆化潔齒條 <br />
                            風味：重量芝士 <br />
                            重量：72g <br />
                            數量：3 條 <br />
                            適合犬型：中型犬和大型犬 <br />
                            建議餵食方法：每星期食用
                        </Typography>
                        <Box sx={{ width: 160 }}>
                            <FormControl fullWidth>
                                <InputLabel id="pz">訂購數量</InputLabel>
                                <Select
                                    labelId="pz_order_lable"
                                    id="pz_order"
                                    size="small"
                                    value={orderqty.pz}
                                    label="訂購數量"
                                    onChange={handleQtyChange('pz')}
                                >
                                    <MenuItem value={0}>0</MenuItem>
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={2}>2</MenuItem>
                                    <MenuItem value={3}>3</MenuItem>
                                    <MenuItem value={4}>4</MenuItem>
                                    <MenuItem value={5}>5</MenuItem>
                                    <MenuItem value={6}>6</MenuItem>
                                    <MenuItem value={7}>7</MenuItem>
                                    <MenuItem value={8}>8</MenuItem>
                                    <MenuItem value={9}>9</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>
                </Grid>
                <Divider style={{ marginTop: '16px', marginBottom: '16px', }}/>
                <Grid container item>
                    <Grid item xs={4}>
                        <img src={hz} alt="Hard Cheese" className={classes.productpic}/>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="body1" style={{marginBottom: '16px',}}>
                        Hard Cheese
                        <br />
                        重量芝士(堅硬)潔齒棒
                        </Typography>
                        <Typography variant="body1" style={{marginBottom: '16px',}}>
                            $138
                        </Typography>
                        <Typography variant="body2" style={{marginBottom: '16px',}}>
                            屬性：剛硬潔齒條 <br />
                            風味：重量芝士 <br />
                            重量：100g <br />
                            數量：4 條 <br />
                            適合犬型：大型犬 <br />
                            建議餵食方法：每星期食用
                        </Typography>
                        <Box sx={{ width: 160 }}>
                            <FormControl fullWidth>
                                <InputLabel id="hz">訂購數量</InputLabel>
                                <Select
                                    labelId="hz_order_lable"
                                    id="hz_order"
                                    size="small"
                                    value={orderqty.hz}
                                    label="訂購數量"
                                    onChange={handleQtyChange('hz')}
                                >
                                    <MenuItem value={0}>0</MenuItem>
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={2}>2</MenuItem>
                                    <MenuItem value={3}>3</MenuItem>
                                    <MenuItem value={4}>4</MenuItem>
                                    <MenuItem value={5}>5</MenuItem>
                                    <MenuItem value={6}>6</MenuItem>
                                    <MenuItem value={7}>7</MenuItem>
                                    <MenuItem value={8}>8</MenuItem>
                                    <MenuItem value={9}>9</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>
                </Grid>
            </Paper>
            {
                //CAT CLEANING SERIES
            }
            <Paper elevation={0} className={classes.paperstyle}>
                <Grid item xs={12}>
                    <Typography variant="h2" style={{marginBottom: '16px',}}>
                        貓隻清潔系列
                    </Typography>
                </Grid>
                <Grid container item>
                    <Grid item xs={4}>
                        <img src={cf} alt="Dental Flakes for Cat" className={classes.productpic}/>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="body1" style={{marginBottom: '16px',}}>
                        Dental Flakes for Cats
                        <br />
                        貓貓專用芝士潔齒粉
                        </Typography>
                        <Typography variant="body1" style={{marginBottom: '16px',}}>
                            $119
                        </Typography>
                        <Typography variant="body2" style={{marginBottom: '16px',}}>
                            屬性：潔齒粉 <br />
                            風味：重量芝士 <br />
                            重量：80g <br />
                            適合犬型：全貓用 <br />
                            建議餵食方法：每天食用
                        </Typography>
                        <Box sx={{ width: 160 }}>
                            <FormControl fullWidth>
                                <InputLabel id="cf">訂購數量</InputLabel>
                                <Select
                                    labelId="cf_order_lable"
                                    id="cf_order"
                                    size="small"
                                    value={orderqty.cf}
                                    label="訂購數量"
                                    onChange={handleQtyChange('cf')}
                                >
                                    <MenuItem value={0}>0</MenuItem>
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={2}>2</MenuItem>
                                    <MenuItem value={3}>3</MenuItem>
                                    <MenuItem value={4}>4</MenuItem>
                                    <MenuItem value={5}>5</MenuItem>
                                    <MenuItem value={6}>6</MenuItem>
                                    <MenuItem value={7}>7</MenuItem>
                                    <MenuItem value={8}>8</MenuItem>
                                    <MenuItem value={9}>9</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>
                </Grid>
                <Divider style={{ marginTop: '16px', marginBottom: '16px', }}/>
                <Grid container item>
                    <Grid item xs={4}>
                        <img src={cm} alt="Cat Mminis" className={classes.productpic}/>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="body1" style={{marginBottom: '16px',}}>
                        Mminis for Cat
                        <br />
                        貓貓專用芝士潔齒粒
                        </Typography>
                        <Typography variant="body1" style={{marginBottom: '16px',}}>
                            $119
                        </Typography>
                        <Typography variant="body2" style={{marginBottom: '16px',}}>
                            屬性：潔齒小食粒 <br />
                            風味：重量芝士 <br />
                            適合犬型：全貓用 <br />
                            建議餵食方法：每天食用
                        </Typography>
                        <Box sx={{ width: 160 }}>
                            <FormControl fullWidth>
                                <InputLabel id="cm">訂購數量</InputLabel>
                                <Select
                                    labelId="cm_order_lable"
                                    id="cm_order"
                                    size="small"
                                    value={orderqty.cm}
                                    label="訂購數量"
                                    onChange={handleQtyChange('cm')}
                                >
                                    <MenuItem value={0}>0</MenuItem>
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={2}>2</MenuItem>
                                    <MenuItem value={3}>3</MenuItem>
                                    <MenuItem value={4}>4</MenuItem>
                                    <MenuItem value={5}>5</MenuItem>
                                    <MenuItem value={6}>6</MenuItem>
                                    <MenuItem value={7}>7</MenuItem>
                                    <MenuItem value={8}>8</MenuItem>
                                    <MenuItem value={9}>9</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>
                </Grid>                    
            </Paper>
            {
                //Summary
            }
            <Paper elevation={0} className={classes.paperstyle}>
                <Grid item xs={12}>
                    <Typography variant="h2">
                        訂購總結
                    </Typography>
                    <Box sx={{my: 2,}}>
                        {
                            orderqty.df > 0 &&
                                <Typography variant="body1">
                                    蕎麥芝士潔齒粉 ($109) x {orderqty.df} 包 
                                </Typography>
                        }
                        {
                            orderqty.s > 0 &&  
                                <Typography variant="body1">
                                蕎麥芝士潔齒小食片 ($109) x {orderqty.s} 包 <br />
                                </Typography>
                        }
                        {
                            orderqty.sn > 0 &&  
                                <Typography variant="body1">
                                角豆芝士潔齒粒 ($109) x {orderqty.sn} 包 <br />
                                </Typography>
                        }
                        {
                            orderqty.hc > 0 &&  
                                <Typography variant="body1">
                                蕎麥芝士(堅硬)潔齒棒 ($109) x {orderqty.hc} 包 <br />
                                </Typography>
                        }
                        {
                            orderqty.pz > 0 &&  
                                <Typography variant="body1">
                                重量芝士(鬆化)潔齒條 ($119) x {orderqty.pz} 包 <br />
                                </Typography>
                        }
                        {
                            orderqty.hz > 0 &&  
                                <Typography variant="body1">
                                重量芝士(堅硬)潔齒棒 ($138) x {orderqty.hz} 包 <br />
                                </Typography>
                        }
                        {
                            orderqty.cf > 0 &&  
                                <Typography variant="body1">
                                貓貓專用芝士潔齒粉 ($119) x {orderqty.cf} 包 <br />
                                </Typography>
                        }
                        {
                            orderqty.cm > 0 &&  
                                <Typography variant="body1">
                                貓貓專用芝士潔齒粒 ($119) x {orderqty.cm} 包 <br />
                                </Typography>
                        }
                    </Box>
                    <Typography variant="body1">
                        貨品數量: {orderqty.total} <br />
                    </Typography>
                    {
                        !orderqty.package ?
                            <Alert severity="warning" sx={{mt: 2, px: 1, py: 0.5 }}>
                                現有買 3 送 1 優惠， 請訂購 4 的倍數數量
                            </Alert>
                            :
                            <Typography variant="body1">
                                總數： ${orderqty.price}
                            </Typography>
                    }
                    <Typography variant="body2" style={{marginTop: '24px', fontStyle: 'italic', fontSize: '0.8rem'}}>
                        優惠詳情： 
                    </Typography>
                        <ol style={{marginLeft: '-24px'}}>
                            <li style={{fontStyle: 'italic', fontSize: '0.8rem', marginTop: '-16px'}}>
                                <Typography variant="body2" style={{fontStyle: 'italic', fontSize: '0.8rem'}}>
                                    買任何 4 件貨品，最低金額的 1 款貨品為贈品
                                </Typography>
                            </li>
                            <li style={{fontStyle: 'italic', fontSize: '0.8rem'}}>
                                <Typography variant="body2" style={{fontStyle: 'italic', fontSize: '0.8rem'}}>
                                    送贈PhiloPet天然免沖洗清潔噴霧1支 (價值$168)
                                </Typography>
                            </li>
                            <li style={{fontStyle: 'italic', fontSize: '0.8rem'}}>
                                <Typography variant="body2" style={{fontStyle: 'italic', fontSize: '0.8rem'}}>
                                    免費順豐送貨
                                </Typography>
                            </li>
                        </ol>
                </Grid>
            </Paper>
            {
                //Customer Info
            }
            <Paper elevation={0} className={classes.paperstyle}>
                <Grid item xs={12}>
                    <Typography variant="h2">
                        送貨資料
                    </Typography>
                    <Box sx={{my: 2,}}>
                        <Box
                            component="form"
                            sx={{
                                '& > :not(style)': { my: 1 },
                            }}
                        >
                            <TextField
                                required
                                fullWidth
                                size='small'
                                id="name"
                                label="姓名"
                                placeholder="姓名"
                                variant="outlined"
                                value={userinfo.name}
                                onChange={handleUserChange('name')}
                            />
                            <TextField
                                required
                                fullWidth
                                size='small'
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                id="phone"
                                label="聯絡電話"
                                placeholder="聯絡電話"
                                variant="outlined"
                                value={userinfo.phone}
                                onChange={handleUserChange('phone')}
                            />
                            <TextField
                                required
                                fullWidth
                                size='small'
                                type="email"
                                id="email"
                                label="E-Mail"
                                placeholder="E-Mail"
                                variant="outlined"
                                value={userinfo.email}
                                onChange={handleUserChange('email')}
                            />
                            <TextField
                                required
                                fullWidth
                                size='small'
                                id="address"
                                label="送貨地址"
                                placeholder="送貨地址"
                                variant="outlined"
                                multiline
                                value={userinfo.address}
                                onChange={handleUserChange('address')}
                            />
                            <Typography variant="body2" style={{marginTop: '-0.5rem', fontStyle: 'italic', fontSize: '0.8rem'}}>
                                <sup>*</sup>如送到順豐站或者順豐智能櫃，只需填寫網點代碼
                            </Typography>
                        </Box>
                    </Box>
                    <Grid item xs={12} align="center" style={{marginTop: '24px',}}>
                        <Button variant="contained"  onClick={() => navigate('payment', { replace: true })} disabled={((orderqty.price > 0) && userinfo.name && userinfo.phone && userinfo.email && userinfo.address) ? false : true } disableElevation>
                            確認訂單                     
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    )
}

