import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyDJZAeWYnBnIDpG8_ZYnNT6ev9v4HnBl58",
    authDomain: "qcxmas2021-3c0e2.firebaseapp.com",  
    projectId: "qcxmas2021-3c0e2",  
    storageBucket: "qcxmas2021-3c0e2.appspot.com",  
    messagingSenderId: "865298491015",  
    appId: "1:865298491015:web:21b59e4c10803e87a27a22",  
    measurementId: "G-BNMSY0M56H"
  };
  
// Initialize Firebase
const app = initializeApp(firebaseConfig)
export const db = getFirestore(app)
export const storage = getStorage(app);