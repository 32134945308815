//import { createMuiTheme } from '@material-ui/core/styles';
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: "#E59717",
    },
    text: {
      primary: "#ffffff",
      secondary: "#000000",
    },
  },
  typography: {
    color: "#000000",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.2,
    letterSpacing: "normal",
    h1: {
      fontSize: "22px",
      fontWeight: "bold",
      color: "#ffffff",
      '@media (min-width:600px)': {
        fontSize: '1.8rem',
      },
    },
    h2: {
      fontSize: "18px",
      color: "#000000",
      '@media (min-width:600px)': {
        fontSize: '1.5rem',
      },
    },
    body1: {
      fontSize: "16px",
      color: "#000000",
      '@media (min-width:600px)': {
        fontSize: '1.25rem',
      },
    },
    body2: {
      fontSize: "14px",
      color: "#000000",
      '@media (min-width:600px)': {
        fontSize: '1rem',
      },
    },
    button: {
      fontSize: "18px",
      fontWeight: "bold",
      color: "#FFFFFF",
    },
  },
  components: {
    // Style sheet name ⚛️
    MuiButton: {
      // Name of the rule
      styleOverrides:{
        root:{
          fontSize: "18px",
          fontWeight: "bold",
          color: "#FFFFFF",
          width: "100%",
          height: "45px",
          borderRadius: "18px",
          '@media (min-width:600px)': {
            fontSize: '1.25rem',
            width: "16rem",
            height: "3rem",
            borderRadius: "30px",
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides:{
        root:{
          color: "#000000",
        },
      },
    },
    MuiFormLabel:{ //Change the TextField's placeholder font
      styleOverrides:{
        root: {
          fontSize: "16px",
          color: "#000000",
          '@media (min-width:600px)': {
            fontSize: '1rem',
          },
        },
      },
    },
    MuiOutlinedInput:{ //Change the TextField's border color -> hover and normal state
      styleOverrides:{
        root:{
          '&:hover .MuiOutlinedInput-notchedOutline':{
            borderColor: "#000000",
          },
        },
      },
    },
    MuiInputBase:{ //Change the TextField input text color
      styleOverrides:{
        input: {
          fontSize: "16px",
          color: "#000000",
          '@media (min-width:600px)': {
            fontSize: '1rem',
          },
        },
      },
    },
  },
});

export default theme;
