import React, { useState, useEffect } from 'react'
//import { db, storage } from './initfirebase'
import { getAuth, onAuthStateChanged } from "firebase/auth"

export const loginStatus = React.createContext()

const LoginProvider = (props) => {
  //Initialize the context value
  const [currentstatus,setCurrentstatus] = useState(false)
  useEffect(() => {
    const auth = getAuth()
    onAuthStateChanged( auth, async loginuser => {
      //console.log(`Login status is changing to ${loginuser}`)
      localStorage.clear()
      if (loginuser) {
        //console.log("You have logged in!")
        setCurrentstatus(true)        
      } else {
        //console.log("Login please")
        setCurrentstatus(false)
      }
    })
  },[])

  return (
    <loginStatus.Provider value={{ currentstatus }}>
      {props.children}
    </loginStatus.Provider>
  )
}

export default LoginProvider

