import React, { useState, useEffect, useContext } from 'react'
import { loginStatus } from '../../LoginProvider'
import { useNavigate, useLocation, Navigate } from 'react-router-dom'
import { Grid, Box, Button } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid';
import { db } from '../../initfirebase'
import { collection, getDocs } from "firebase/firestore"
import { signout } from './authhandling'

const columns = [
    { field: 'id', headerName: 'ID', width: 50 },
    { field: 'status', headerName: 'Status', width: 100 },
    { field: 'name', headerName: 'Name', width: 120 },
    { field: 'phone', headerName: 'Phone', width: 120 },
    { field: 'address', headerName: 'Address', width: 560 },
    { field: 'totalqty', headerName: 'Quantity', width: 80 },
    { field: 'price', headerName: 'Price', width: 100 },
    { field: 'df', headerName: 'Dental Flakes', width: 100 },
    { field: 's', headerName: 'Sizzles', width: 100 },
    { field: 'sn', headerName: 'Snackies', width: 100 },
    { field: 'hc', headerName: 'Hard Cracker', width: 100 },
    { field: 'pz', headerName: 'Puffed Cheese', width: 100 },
    { field: 'hz', headerName: 'Hard Cheese', width: 100 },
    { field: 'cf', headerName: 'Cat Flakes', width: 100 },
    { field: 'cm', headerName: 'Cat Mminis', width: 100 },
    { field: 'pd', headerName: 'Detergent', width: 100 },
    /*
    {
      field: 'fullName',
      headerName: 'Full name',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 160,
      valueGetter: (params) =>
        `${params.getValue(params.id, 'firstName') || ''} ${
          params.getValue(params.id, 'lastName') || ''
        }`,
    },
    */
  ];

export default function Orderdisplay() {
    const { currentstatus } = useContext(loginStatus) //Load the context login state
    console.log (currentstatus)
    let location = useLocation()

    const navigate = useNavigate()

    const [logout, setLogout] = useState(false)
    useEffect(()=>{
        if (logout) {
            console.log("Logout!")
            signout()
            navigate('/', { replace: true })
        }
    })

    const [orderlist, setOrderlist] = useState()
    useEffect(()=>{
        console.log(orderlist)
        let reading =true
        if (!orderlist) {
            getDocs(collection(db, "orders")).then((orders)=>{
                let templist = []
                let id = 0
                orders.forEach((order)=>{
                    id = id + 1
                    let data = {
                        id: id,
                        name: order.data().name,
                        phone: order.data().phone,
                        address: order.data().address,
                        cf: order.data().orderitems.cf,
                        cm: order.data().orderitems.cm,
                        df: order.data().orderitems.df,
                        hc: order.data().orderitems.hc,
                        hz: order.data().orderitems.hz,
                        pd: order.data().orderitems.pd,
                        pz: order.data().orderitems.pz,
                        s: order.data().orderitems.s,
                        sn: order.data().orderitems.sn,
                        totalqty: order.data().totalqty,
                        price: `$${order.data().price}`,
                        status: "Preparing"
                    }
                    console.log (data)
                    templist.push(data)
                })
                if (reading) {
                    setOrderlist(templist)
                }
            })
            .catch((err)=>{console.log(err.message)})
        }
        return () => {reading = false}
    },[orderlist])

    if (orderlist) {
        console.log(orderlist)
    }
        
    return (
        <React.Fragment>
        {
            !currentstatus ?
            <Navigate to="/" state={{ from: location }} />
            :
            <React.Fragment>
                <Box justifyContent="center" sx={{my: 2, display: 'flex', flexWrap: 'wrap',}} >
                    <Button variant="contained" onClick={()=>setLogout(true)} disableElevation>
                        LOG OUT                      
                    </Button>
                </Box>
            {
                orderlist &&
                <Grid container>
                    <Grid item xs={12} align="center" sx={{height: 800, width: 1}}>
                        <DataGrid
                            rows={orderlist}
                            columns={columns}
                            pageSize={15}
                            rowsPerPageOptions={[15]}
                            checkboxSelection
                            style={{fontSize: '0.75rem'}}
                        />
                    </Grid>
                </Grid>
            }
            </React.Fragment>
        }
        </React.Fragment>
    )
}
