import React, { useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Box } from '@mui/material'
import Expired from './components/Expired'
import Banner from './components/Banner'
import Order from './components/Order'
import Payment from './components/Payment'
import Finished from './components/Finished'
import Orderdisplay from './components/internal/Orderdisplay'

export default function App() {

  const [orderqty, setOrderqty] = useState({
      df: 0,
      s: 0,
      sn: 0,
      hc: 0,
      pz: 0,
      hz: 0,
      cf: 0,
      cm: 0,
      total: 0,
      price: 0,
      package: false,
  });
  //useEffect(()=>{ console.log (orderqty) },[orderqty])

  const [userinfo, setUserinfo] = useState({
      name: '',
      phone: '',
      email: '',
      address: '',
      orderid: '',
  })
  //useEffect(()=>{ console.log (userinfo) },[userinfo])


  return (
    <BrowserRouter>
      <Box>
        <Banner />
        <Routes>
          <Route path='/' element={<Expired />} />
          <Route path='/Order.js' element={<Order orderqty={orderqty} setOrderqty={setOrderqty} userinfo={userinfo} setUserinfo={setUserinfo} />} />
          <Route path='/payment' element={<Payment orderqty={orderqty} setOrderqty={setOrderqty} userinfo={userinfo} setUserinfo={setUserinfo} />} />
          <Route path='/finished' element={<Finished userinfo={userinfo} />} />
          <Route path='/orderdisplay' element={<Orderdisplay />} />
        </Routes>
      </Box>
    </BrowserRouter>
  );
}
