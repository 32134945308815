import React, {useContext} from 'react'
import { loginStatus } from '../LoginProvider'
import { makeStyles } from '@mui/styles';
import { Grid, Typography } from '@mui/material'

import logo from '../img/logo.png'

const useStyles = makeStyles({
    bannerbg: {
        backgroundColor: '#E59717',
        paddingTop: '24px',
        paddingBottom: '24px',
        '@media (min-width:600px)': {
            paddingTop: '2rem',
            paddingBottom: '2rem',
        },
    },
    logo: {
        width: "100px",
        height: "auto",
        marginBottom: "8px",
        '@media (min-width:600px)': {
            width: "160px",
            height: "auto",
            marginBottom: "1.2rem",
        },
    },
  });

export default function Banner() {
    const { currentstatus } = useContext(loginStatus) //Load the context login state
    console.log(currentstatus)

    const classes = useStyles()
    return (
        <Grid container className={classes.bannerbg} justifyContent="center" alignItems="center">
            <Grid item xs={12} align="center">
                <Grid>
                    <img src={logo} alt="Logo" className={classes.logo}/>
                </Grid>
            </Grid>
            {
                !currentstatus &&
                <Grid item xs={12}>
                    <Typography variant="h1" align="center">
                        QCHEFS 聖誕優惠                        
                        <br />
                        買 3 送 1 訂購專頁
                    </Typography>
                    <Typography variant="body2" align="center" style={{color: '#ffffff', marginTop: '8px'}}>
                        <sup>*</sup>再送價值 $168 PhiloPet 免洗清潔噴霧
                    </Typography>
                </Grid>
            }
        </Grid>
    )
}
