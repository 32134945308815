import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom"
import { signin } from './authhandling'
import { Box, Grid, Typography, TextField, Button } from '@mui/material'

export default function LogIn() {
    

    let navigate = useNavigate()

    //Store the credentials data
    const [credentials, setCredentials] = useState({
        email: '',
        password: '',
        error: '',
    })
    useEffect(()=>{
        console.log (credentials)
    },[credentials])

    //Login flag hook
    const [login, setLogin] = useState(false)
    useEffect (()=>{
        if (login) {
            console.log ("Login Clicked")
            
            if ( credentials.email !== '' && credentials.password !== '' ) { //Make sure no credential info is missing
                signin(credentials.email, credentials.password) //Call the function signin()
                .then((result) => {
                    console.log(result)                
                    navigate('/orderdisplay', { replace: true })
                })
                .catch((error) => { //Failure to login
                    console.log (error)
                    setCredentials({ ...credentials, error }) //Capture the error message
                })
            } else { //if something in the form is missing
                let errormsg = ''
                if (credentials.email === '') { errormsg = 'Email cannot be empty! ' }
                if (credentials.password === '') { errormsg = errormsg + 'Password cannot be empty! ' }
                setCredentials({ ...credentials, error: errormsg })  //Capture the error message
            }

        }
        return () => {setLogin(false)}
    },[ login, credentials, navigate ])
    
    
    //Fired by the Login button clicked
    const handleLogIn = (event) => {
        setLogin(true)
    }
    
    //Store the input value into the hook credentials
    const handleChange = (prop) => (event) => {
        setCredentials({ ...credentials, [prop]: event.target.value, error: '' }) //For the email and password and reset the error message once typed something
    }

    return (
        <React.Fragment>
            { //Display the captured error message if it exists
                credentials.error!=='' &&
                    <Grid item xs={12} align="center">
                        <Typography id="errormessage" variant="body1" align="center" sx={{mt: 4, color: '#FF0000'}}>
                            {credentials.error}
                        </Typography>               
                    </Grid>
            }
            <Box>
                <Typography paragraph variant="body1" align="center" sx={{mt: 4}}>
                    Internal Login <br />
                </Typography>
            </Box>
            <form >
                <Grid item xs={12} align="center">
                    <TextField
                        required
                        //fullWidth
                        size='small'
                        id="email"
                        label="Email"
                        placeholder="Email"
                        variant="outlined"
                        value={credentials.email}
                        onChange={handleChange('email')}
                        sx={{mt: 4, width: '30ch'}}
                    />
                    <TextField
                        required
                        //fullWidth
                        size='small'
                        type="password"
                        id="password"
                        label="Password"
                        placeholder="Password"
                        variant="outlined"
                        value={credentials.password}
                        onChange={handleChange('password')}
                        sx={{my: 4, width: '30ch'}}
                    />            
                </Grid>
                <Grid item xs={12} align="center">
                    <Button variant="contained"  onClick={handleLogIn} disabled={(login) ? true : false } disableElevation>
                        LOG IN                      
                    </Button>
                </Grid>
            </form>
        </React.Fragment>
    )
}
