import React, { useContext } from 'react'
import { loginStatus } from '../LoginProvider'
import { useNavigate } from "react-router-dom"
import { makeStyles } from '@mui/styles'
import { Box, Grid, Typography, Paper, Divider, Button } from '@mui/material'
import Login from './internal/LogIn'

const useStyles = makeStyles({
    paperstyle: {
        margin: '8px 0',
        padding: '24px 8px',
        width: '100%',
        '@media (min-width:600px)': {
            maxWidth: "600px",
            padding: '32px 32px',
            margin: '0.75rem 0',
        },
    },
  });

export default function Expired() {
    const { currentstatus } = useContext(loginStatus) //Load the context login state
    console.log (currentstatus)

    let navigate = useNavigate()

    const classes = useStyles()

    let emailmsg = `mailto:info@go2health.com.hk?subject=Questions about QCHEFS`
    let wamsg = `https://wa.me/+85265582523?text=我想查詢有關QCHEFS的問題`

    return (
        <React.Fragment>
        {
            <Box sx={{ //Background
                    p: 2,
                    display: 'flex',
                    flexWrap: 'wrap',
                }}
                justifyContent="center"
            >
                <Paper elevation={0} className={classes.paperstyle}>
                    <Grid item xs={12}>
                        <Typography paragraph variant="h2" align="center">
                            聖誕優惠活動已經結束了！ <br />
                        </Typography>
                        <Typography paragraph variant="h2" align="center">
                            多謝支持 QCHEFS 產品 <br />
                        </Typography>
                        <Typography paragraph variant="h2" align="center">
                            如有任何問題，歡迎隨時跟我們聯絡。 <br />
                        </Typography>
                        <Typography paragraph variant="h2" align="center" style={{fontSize: '1rem'}}>
                            E-Mail: <a href={emailmsg}>info@go2health.com.hk</a><br />
                            WhatsApp: <a href={wamsg} target="_blank" rel="noreferrer">(852) 65582523</a>
                        </Typography>
                    </Grid>
                    <Divider sx={{mt:4}}/>
                    <Grid item xs={12}>
                        {
                            currentstatus ?
                            <Box justifyContent="center" sx={{ //Background
                                my: 4,
                                display: 'flex',
                                flexWrap: 'wrap',                                
                            }}>
                                <Button variant="contained"  onClick={()=>navigate('/orderdisplay', { replace: true })} disableElevation>
                                    Internal page                    
                                </Button>
                            </Box>
                            :
                            <Login />
                        }
                    </Grid>
                </Paper>
            </Box>
        }
        </React.Fragment>
    )
}
